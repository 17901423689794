import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener,  Input,  input,  Output, TemplateRef, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'sod-button-filter',
  templateUrl: './button-filter.component.html',
  styleUrls: ['./button-filter.component.scss'],
  standalone: true,
  imports: [CommonModule, MatMenuTrigger, MatButtonModule, MatMenuModule],
})
export class ButtonFilterComponent {



  @Output() onOpened = new EventEmitter<void>();
  @Output() onClose: EventEmitter<void> = new EventEmitter();
 @Input() content : TemplateRef<any>;
  @ViewChild("noContent") noContent! : TemplateRef<any>;
  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;
  constructor() {
    this.content = this.noContent;
  }
  menuOpen() {
   this.onOpened.emit();
  }
  handleMenuClose() {
    console.log('Menu closed');
  }
  @HostListener('document:keydown.escape', ['$event'])
  handleEscape(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.menuTrigger.closeMenu();

    }
  }
  closeEvent() {
    this.onClose.emit();
  }
  close() {
    this.menuTrigger?.closeMenu();
  }



}
