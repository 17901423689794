import { ComponentType } from '@angular/cdk/overlay';
import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { DialogData } from '@sod/core/models/popup.model';
import { PopupConfirmComponent } from '@sod/shared/components';
import { ToastMessageComponent } from '@sod/shared/components/toast-message/toast-message.component';
import { Observable, first, map } from 'rxjs';
import { HeaderService } from './header.service';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  header = inject(HeaderService);
  constructor(private dialog: MatDialog, private _snackBar: MatSnackBar) { }

  openPopup(data: DialogData): Observable<'yes' | 'no' | 'cancel'> {
    const dialogRef = this.dialog.open(PopupConfirmComponent, { data: data });
    return dialogRef.afterClosed().pipe(map((x: 'yes' | 'no' | 'cancel') => x));
  }
  openFilter<T, D>(component: ComponentType<T>, data: D) {
    const dialogRef = this.dialog.open(component, { data: data });
    return dialogRef.afterClosed().pipe(first()) as Observable<D>;
  }
  showToast(
    message: string,
    messageType: 'success' | 'error' | 'warning' = 'success',
    options?: {
      duration?: number | undefined,
      horizontalPosition?: MatSnackBarHorizontalPosition | undefined,
      verticalPosition?: MatSnackBarVerticalPosition | undefined,
    }
  ) {
    const icon = this.setIconPanel(messageType);
    const ref = this._snackBar.openFromComponent(ToastMessageComponent, {
      duration: 2300,
      data: { message, icon },
      panelClass: [this.setPanelClass(messageType)],
      horizontalPosition: 'right',
      verticalPosition: 'top',
      ...options
    });
    return ref.afterDismissed();
  }

  setPanelClass(messageType: 'success' | 'error' | 'warning') {
    if (messageType === 'success') return 'success-toast'
    if (messageType === 'warning') return 'warning-toast';
    return 'error-toast';
  }

  setIconPanel(messageType: 'success' | 'error' | 'warning') {
    if (messageType === 'success') return 'check_circle'
    if (messageType === 'warning') return 'warning';
    return 'error';
  }
  showLoading(key: string): void {
    this.header.showLoading(key);
  }
  hideLoading(key: string): void {
    this.header.hideLoading(key);
  }
}
