import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA,  MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@sod/core/models/popup.model';


@Component({
  selector: 'sod-popup-confirm',
  templateUrl: './popup-confirm.component.html',
  styleUrl: './popup-confirm.component.scss',
})
export class PopupConfirmComponent {
  constructor(
    public dialogRef: MatDialogRef<PopupConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}
  get status() { return this.data.status ?? 'warning' }
  get title(){ return this.data.tittle ?? this.status }
  get icon(){ return this.data.icon ?? this.status }
  onClick(action : string ): void {
    this.dialogRef.close(action);
  }
}
