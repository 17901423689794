import { NgIf, NgStyle } from '@angular/common';
import { Component, computed, EventEmitter, forwardRef, Input, Output, signal, ViewChild } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { BubblePaginationDirective } from '@sod/shared/components/custom-pagination/bubble-pagination.directive';
import { InputNumberDirective } from '@sod/shared/directives/input-number.directive';

@Component({
  selector: 'sod-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrl: './custom-pagination.component.scss',
  standalone: true,
  imports: [
    MatPaginator, ReactiveFormsModule, BubblePaginationDirective, NgStyle, MatFormFieldModule, MatSelectModule, ReactiveFormsModule, FormsModule, NgIf, InputNumberDirective
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomPaginationComponent),
      multi: true,
    },
  ],
})
export class CustomPaginationComponent {


  @Input() set totalList(value: number | null | undefined) {
    this._totalList.set(value || 0);
  }
  @Output() totalListChange = new EventEmitter<number>();
  @Input() set pageSize(value: number | undefined | null) {
    this._pageSize.set(!value || value <= 0 ? 10 : value);
  }
  @Output() pageSizeChange = new EventEmitter<number>();
  @Input() set currentPage(value: number | undefined | null) {
    this._currentPage.set(!value || value <= 0 ? 0 : value);
  }
  @Input() set pageSizeOptions(value: number[] | undefined | null) {
    this._pages.set(value || [10, 20, 50]);
  }
  @Output() currentPageChange = new EventEmitter<number>();
  @Output() onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  protected _totalList = signal<number>(0);
  protected _currentPage = signal<number>(0);
  protected _pageSize = signal<number>(10);
  protected _pages = signal<number[]>([10, 20, 50]);

  protected pages = computed(() => this._pages());
  protected maxValue = computed(() => Math.ceil(this._totalList() / this._pageSize()))
  protected showChangePage = computed(() => this.maxValue() < 5)
  protected maxWidth = computed(() => (this.maxValue() + '').length)
  protected totalLastViewer = computed(() => {
    const maxPage = (this._currentPage() + 1) * this._pageSize()
    const totalList = this._totalList();

    return maxPage < totalList ? maxPage : totalList;
  })


  onPageSizeChange(event: MatSelectChange) {
    this._pageSize.set(event.value);
    this._currentPage.set(0);
    this.pageSizeChange.emit(event.value);
    this.currentPageChange.emit(0);
  }


  handlePageChange(e: PageEvent) {
    this._pageSize.set(e.pageSize);
    this._currentPage.set(e.pageIndex);
    this.pageSizeChange.emit(e.pageSize);
    this.currentPageChange.emit(e.pageIndex);
    this.onPageChange.emit(e);
  }


  enterPress(arg0: string) {
    const page = parseInt(arg0) - 1;
    const previousPageIndex = this.paginator.pageIndex;
    this.paginator['_pageIndex'] = page;
    this.paginator['_emitPageEvent'](previousPageIndex);
  }
}
