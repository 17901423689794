import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { HeaderService } from './../../services/header.service';
import { Directive, ElementRef, forwardRef, Input, OnInit, Renderer2, SimpleChanges, TemplateRef } from '@angular/core';

@Directive({
  selector: '[sod-tittle]',
  standalone: true,
})
export class HeaderTittleDirective implements OnInit {
  @Input() title: string = '';
  @Input() showBack: boolean = false;
  @Input() backFunction!: Function;
  @Input() extraRight: TemplateRef<any> | null = null;;
  constructor(private el: ElementRef, private renderer: Renderer2, private headerService: HeaderService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['title']) {
      this.headerService.setHeaderTittle(changes['title'].currentValue);
      this.renderer?.removeChild(this.el.nativeElement.parentElement, this.el.nativeElement);
    }
  }

  ngOnInit(): void {
    const title = this.el.nativeElement.innerText || "";
    this.headerService.setHeaderTittle(title);
    this.renderer?.removeChild(this.el.nativeElement.parentElement, this.el.nativeElement);
    this.headerService.setHeaderButton(this.showBack);
    this.headerService.sendFunction(this.backFunction);
    this.extraRight &&  this.headerService.setHeaderExtraContent(this.extraRight);
  }

  onClick() {
    if (this.backFunction) {
      this.backFunction();
    }
  }


}
