<div class="wapper-confirm">
  <h1 *ngIf="data.tittle">{{ title }} </h1>
  <div class="decryption">
    <div class="wrapper-icon">
      <img src="/assets/svg/{{icon}}.svg" alt="" />
    </div>
    <div class="text-decryption flex-grow-1"> {{ data.body }}</div>
  </div>

  <div class="action-popup">
    <button mat-button class="btn btn-secondary w-100" (click)="onClick('cancel')">Cancel</button>
    <button mat-button class="btn btn-primary w-100" (click)="onClick('yes')" cdkFocusInitial>Confirm</button>
  </div>


</div>
