<div class="status-body" ngClass="{{getClass(status)}}">
  <span *ngIf="status !== Type.PROCESS" class="label">
    {{ getLabel(status) }}
  </span>
  <div *ngIf="status === Type.PROCESS" class="d-flex flex-row justify-content-between align-items-center process">
    <span class="percent-number">{{ percent }}</span>
    <mat-progress-spinner class="progress-margin"  mode="determinate" [value]="percent">
    </mat-progress-spinner>
  </div>
</div>
