<button
  mat-button
  [matMenuTriggerFor]="menu"
  (onMenuOpen)="menuOpen()"
  (menuClosed)="closeEvent()"
  #menuTrigger="matMenuTrigger"
  class="btn-filter"
>
  <img alt="icon" src="./assets/svg/IcFilter.svg" /> <span>Filter</span>
</button>
<mat-menu #menu="matMenu" class="my-menu">
  <ng-template matMenuContent>
  <div class="menu-content" (click)="$event.stopPropagation()">
    <ng-content></ng-content>
  </div>
</ng-template>
<ng-template #noContent></ng-template>
</mat-menu>
