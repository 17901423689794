<div class="wrapper-pagination">
  <div class="form-row ">
    <div class="wrapper-paging">
      <div class="sod-flex text-center">
        <mat-form-field class="select-field">
          <mat-select  name="page Size" [(ngModel)]="_pageSize" (selectionChange)="onPageSizeChange($event)">
            @for (page of pages(); track page) {
            <mat-option [value]="page">{{ page }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <span>/Page</span>
      </div>
      <div>
        <span>Showing {{ (_currentPage()) * _pageSize() + 1 }} - {{ totalLastViewer() }} of <b>{{ _totalList() }}</b> items</span>
      </div>
    </div>
    <div class="wrapper-paging sod-ml-1" *ngIf="maxValue()>4">
      <span>Go to page</span>
      <input type="text" #txtVal matInput
      [disabled]="showChangePage()"
      [ngStyle]="{'max-width': 'min( '+ maxWidth() * 11 + 20 +'px, 2.8rem + '+maxWidth() * 11+'px) '}"
      class="input-form current-page-input"
      sodInputNumber
      [max]="maxValue()"
      [min]="1"
      (keydown.enter)="enterPress(txtVal.value)">
    </div>
  </div>

  <mat-paginator sodBubblePagination
  [appCustomLength]="_totalList()"
  [appCustomPageIndex]="_currentPage()"
  [pageSizeCustom]="_pageSize()"
  (pageChangeEmit)="handlePageChange($event)"

  aria-label="Select page">
  </mat-paginator>
</div>
