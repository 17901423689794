import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[sodInputNumber]',
  standalone: true,
})
export class InputNumberDirective {

  @Input() max : number | null | undefined = null
  @Input() min : number | null | undefined = null
  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.renderer.setAttribute(this.el.nativeElement, 'autocomplete', 'off');

    this.renderer.setAttribute(this.el.nativeElement, 'readonly', 'true');
  }

  @HostListener('focus')
  onFocus() {
    this.renderer.removeAttribute(this.el.nativeElement, 'readonly');
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
    const isNumber = /^[0-9]$/;

    const isCtrlOrCmd = event.ctrlKey || event.metaKey;
    const ctrlA = isCtrlOrCmd && event.key === 'a';
    const ctrlC = isCtrlOrCmd && event.key === 'c';
    const ctrlV = isCtrlOrCmd && event.key === 'v';
    const ctrlX = isCtrlOrCmd && event.key === 'x';

    if (!isNumber.test(event.key) && !allowedKeys.includes(event.key) && !ctrlA && !ctrlC && !ctrlV && !ctrlX) {

      event.preventDefault();
    }
  }


  // Handle drop events to allow only numbers
  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();

    const textData = event.dataTransfer?.getData('text') || '';
    const sanitizedData = textData.replace(/[^0-9]/g, '');

    const inputElement = event.target as HTMLInputElement;
    inputElement.value = sanitizedData;
  }

  // Handle paste events to allow only numbers
  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedInput = clipboardData?.getData('text') || '';

    const sanitizedInput = pastedInput.replace(/[^0-9]/g, '');

    if (sanitizedInput !== pastedInput) {
      event.preventDefault();
      const inputElement = event.target as HTMLInputElement;
      inputElement.value = sanitizedInput;
    }
  }

  @HostListener('input', ['$event'])
  validateValue(event: Event) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '');
    if(this.max) {
      const value = parseInt(input.value, 10);

      if (value > this.max) {
        input.value = this.max.toString(); // Reset to max value
      }
    }
    if (this.min) {
      const value = parseInt(input.value, 10);

      if (value < this.min) {
        input.value = this.min.toString(); // Reset to max value
      }
    }
  }
 }
