
<div class="common-upload borer-gray">
    <p class="title-upload">Upload Video</p>
    <div class="d-flex flex-column justify-content-between align-items-center gap-3 body-upload" >
        <div class="upload-icon borer-gray">
            <label class="upload-button">
                <input class="upload-input" type="file" (change)="Upload($event)" />
                <svg class="upload-image" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M30.7179 16.0003C30.7179 19.4312 27.9261 22.2225 24.4957 22.2225H20.0512C19.5599 22.2225 19.1623 21.8245 19.1623 21.3337C19.1623 20.8428 19.5599 20.4448 20.0512 20.4448H24.4957C26.9461 20.4448 28.9401 18.4508 28.9401 16.0003C28.9401 13.5499 26.9461 11.5559 24.4957 11.5559H23.6068C23.1154 11.5559 22.7179 11.1579 22.7179 10.667C22.7179 7.2361 19.9261 4.44477 16.4957 4.44477C13.0652 4.44477 10.2734 7.2361 10.2734 10.667C10.2734 11.1579 9.87588 11.5559 9.38455 11.5559H8.49566C6.04522 11.5559 4.05122 13.5499 4.05122 16.0003C4.05122 18.4508 6.04522 20.4448 8.49566 20.4448H12.9401C13.4314 20.4448 13.829 20.8428 13.829 21.3337C13.829 21.8245 13.4314 22.2225 12.9401 22.2225H8.49566C5.06522 22.2225 2.27344 19.4312 2.27344 16.0003C2.27344 12.5694 5.06522 9.7781 8.49566 9.7781H8.54522C8.98877 5.78366 12.3845 2.66699 16.4957 2.66699C20.6068 2.66699 24.0026 5.78366 24.4461 9.7781H24.4957C27.9263 9.7781 30.7179 12.5694 30.7179 16.0003ZM19.4228 18.4065C19.5963 18.5801 19.8239 18.667 20.0512 18.667C20.2785 18.667 20.5061 18.5801 20.6797 18.4065C21.027 18.0592 21.027 17.4968 20.6797 17.1497L17.1241 13.5941C16.7768 13.2468 16.2143 13.2468 15.8672 13.5941L12.3117 17.1497C11.9643 17.497 11.9643 18.0594 12.3117 18.4065C12.659 18.7537 13.2214 18.7539 13.5685 18.4065L15.6068 16.3683V28.4448C15.6068 28.9357 16.0043 29.3337 16.4957 29.3337C16.987 29.3337 17.3846 28.9357 17.3846 28.4448V16.3683L19.4228 18.4065Z" fill="#0D6999"/>
                </svg>
            </label>
        </div>
        <div class="d-flex flex-column justify-content-between align-items-center gap-1 text-upload">
            <p><span class="click">Click to upload</span> or drag and drop</p>
            <p>Videos will be visible to users until course administrator publish the seminar.</p>
        </div>
    </div>
</div>
