import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-upload-video',
  templateUrl: './upload-video.component.html',
  styleUrls: ['./upload-video.component.scss']
})
export class UploadVideoComponent {
    @Output() uploadFile : EventEmitter<File> = new EventEmitter();

    Upload($event: any) {
      const files = $event.target.files;
      this.uploadFile.emit(files);
    }
}
