import { AbstractControl } from "@angular/forms";
import moment from "moment";

export enum StatusUpload {
  NEW,
  FAIL,
  PROCESS,
  PENDING,
}
export const PAGINATION_DEFAULT = {
  PAGE_DEFAULT: 1,
  SIZE_DEFAULT: 10,
}

export enum StatusVideo {
  NONE,
  SUCCESS,
  PROCESS,
  PUBLIC,
  UN_PUBLIC,
  TRANSCODING = 20,
}

export enum SeminarStatus {
  DRAFT = 0,
  PUBLISHED = 1,
  UNPUBLISHED = 2,
}

export enum StatusMyLearning{
  INPROGRESS,
  COMPLETED
}
export const LabelTabMyLearning = {
  ALL_SEMINARS: 'All Seminars',
  INPROGRESS:'In Progress',
  COMPLETED:'Completed'
}

export const ApiUrl ={
  STREAM_API_URL: "STREAM_API_URL",
  BASE_API_URL: "BASE_API_URL",
}

export const VideoWatchPercent ={
  Full: 0.8,
  Seek: 0.1
}
export const defaultLanguage = 'Cantonese'

export enum TypeSeminar {
  ALL,
  RECOMMENDED,
  NEWRELEASES,
  MOSTVIEWED,
}

export class SodValidate{

  static  smallerThan(otherControlName: string) {
     return (control: AbstractControl): { [key: string]: boolean } | null => {
         if (!control.parent) {
             return null; // Control is not yet associated with a parent.
         }
         const thisValue = control.value;
         const otherValue = (control.parent.get(otherControlName) as AbstractControl).value;

         if(!!thisValue && !!otherValue && thisValue > otherValue){
            return {
                'smallerthan': true
            }
         }
         return null;
     };
   }

   static greaterThan(otherControlName: string) {
     return (control: AbstractControl): { [key: string]: boolean } | null => {
         if (!control.parent) {
             return null; // Control is not yet associated with a parent.
         }

         const thisValue = control.value;
         const otherValue = (control.parent.get(otherControlName) as AbstractControl).value;

         if(!!thisValue && !!otherValue && thisValue < otherValue){
            return {
                'greaterthan': true
            }
         }
         return null;
     };
   }
  static MinValue(min: number) {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const rawValue = control.value;
      const thisValue = parseInt( control.value || '') || 0;

      if (!!rawValue &&  thisValue < min) {
        return { 'min': true }
      }
      return null;
    };
  }

  static MaxValue(max: number) {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const rawValue = control.value;
      const thisValue = parseInt(control.value || '') || 0;

      if (!!rawValue && thisValue > max) {
        return { 'min': true }
      }
      return null;
    };
  }

 }
export const listRating=[
  { value: 1, viewValue: '1' },
  { value: 2, viewValue: '2' },
  { value: 3, viewValue: '3' },
  { value: 4, viewValue: '4' },
  { value: 5, viewValue: '5' },
  { value: 6, viewValue: '6' },
  { value: 7, viewValue: '7' },
  { value: 8, viewValue: '8' },
  { value: 9, viewValue: '9' },
  { value: 10, viewValue: '10' },
]
