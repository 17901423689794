import { StatusUpload, StatusVideo } from '@sod/shared/constants';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
@Component({
  selector: 'sod-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  standalone: true,
  imports: [CommonModule,MatProgressSpinnerModule],
})
export class StatusComponent {
  @Input() status: number = 0;
  @Input() percent: number = 0;
  @Input('type') type: string = '';
  // UploadStatus = StatusUpload;
  // VideoStatus = StatusVideo;
  get Type() {
    switch (this.type) {
      case 'StatusUpload': return StatusUpload;
      case 'StatusVideo': return StatusVideo;
      default: return StatusVideo;
    }
  }
  getClass(status: number): string { return `${this.Type[status]}` }
  getLabel(status: number): string { return `${this.Type[status]}` }

}
